import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Carousel({
  children,
  arrows = true,
  dots = false,
  slidesToShow = 1,
  slidesToShowMobile = 1,
  slidesToScroll = 1,
  speed = 500,
  width = "w-full",
  autoPlay = false,
  autoPlaySpeed = 2000,
  arrowPosition = "topCenter",
  iconBg = "bg-[#E7F3EB]",
  vertical = false,
  verticalSwiping = false,
  responsive,
  infinite = true,
  centerMode = false,
  centerPadding = "0",
  iconSize = "w-9 md:w-16 h-9 md:h-16",
  prevIconClass = "relative left-0 xl:-left-14",
  nextIconClass = "relative right-0 xl:-right-14",
  leftArrowIcon = (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.7 0.724952C6.9 0.924952 6.996 1.16662 6.988 1.44995C6.98 1.73329 6.87566 1.97495 6.675 2.17495L3.85 4.99995L15 4.99995C15.2833 4.99995 15.521 5.09595 15.713 5.28795C15.905 5.47995 16.0007 5.71728 16 5.99995C16 6.28328 15.904 6.52095 15.712 6.71295C15.52 6.90495 15.2827 7.00062 15 6.99995L3.85 6.99995L6.7 9.84995C6.9 10.05 7 10.2876 7 10.563C7 10.8383 6.9 11.0756 6.7 11.275C6.5 11.475 6.26233 11.575 5.987 11.575C5.71166 11.575 5.47433 11.475 5.275 11.275L0.699997 6.69995C0.599997 6.59995 0.528996 6.49162 0.486996 6.37495C0.444996 6.25828 0.424329 6.13328 0.424995 5.99995C0.424995 5.86662 0.445997 5.74162 0.487997 5.62495C0.529997 5.50828 0.600663 5.39995 0.699997 5.29995L5.3 0.699952C5.48333 0.516619 5.71233 0.424951 5.987 0.424951C6.26166 0.424951 6.49933 0.524952 6.7 0.724952Z"
        fill="black"
      />
    </svg>
  ),
  rightArrowIcon = (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.3 11.275C9.1 11.075 9.004 10.8334 9.012 10.55C9.02 10.2667 9.12434 10.025 9.325 9.82505L12.15 7.00005H1C0.71667 7.00005 0.479004 6.90405 0.287004 6.71205C0.0950036 6.52005 -0.000663206 6.28272 3.4602e-06 6.00005C3.4602e-06 5.71672 0.0960036 5.47905 0.288004 5.28705C0.480004 5.09505 0.717337 4.99938 1 5.00005H12.15L9.3 2.15005C9.1 1.95005 9 1.71238 9 1.43705C9 1.16172 9.1 0.924382 9.3 0.725049C9.5 0.525049 9.73767 0.425049 10.013 0.425049C10.2883 0.425049 10.5257 0.525049 10.725 0.725049L15.3 5.30005C15.4 5.40005 15.471 5.50838 15.513 5.62505C15.555 5.74172 15.5757 5.86672 15.575 6.00005C15.575 6.13338 15.554 6.25838 15.512 6.37505C15.47 6.49172 15.3993 6.60005 15.3 6.70005L10.7 11.3C10.5167 11.4834 10.2877 11.575 10.013 11.575C9.73834 11.575 9.50067 11.475 9.3 11.275Z"
        fill="black"
      />
    </svg>
  ),
}) {
  const controlsStyle = {
    topCenter:
      "absolute left-0 w-full flex justify-between items-center top-1/2 transform -translate-y-1/2 z-10",

    topCenterhero:
      "absolute left-0 w-full flex justify-between items-center top-1/2 transform -translate-y-1/2 -z-10 mx-4",
    bottomCenter: " ",
    rightCorner: "lg:flex lg:justify-end hidden lg:block mt-10 mr-32",
    topCenterMobBottom:
      "static md:absolute left-0 w-full top-1/2 transform -translate-y-1/2 z-10 flex justify-between items-center w-full max-w-[120px] md:max-w-full mx-auto",
    verticalCenter:
      "absolute w-[140%] lg:w-[130%] left-[-35%]  xl:w-[106%] top-[52%] xl:left-[-18%] rotate-90 left-0 right-0 flex justify-between transform -translate-y-1/2 z-10 ",
  };
  const [slider, setSlider] = useState();
  const sliderSetting = {
    // vertical: true,
    // verticalSwiping: true,
    // adaptiveHeight: true,
    // variableHeight: false,
    arrows: arrows,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    autoplay: autoPlay,
    autoplaySpeed: autoPlaySpeed,
    infinite: infinite,
    centerMode: centerMode,
    centerPadding: centerPadding,
    speed: speed,
    dots: dots,
    vertical: vertical,
    verticalSwiping: verticalSwiping,
    responsive: responsive || [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div
      className={`relative ${width} ${dots ? "pb-8" : null} ${
        centerMode && "center-carousel"
      }`}
    >
      <Slider ref={setSlider} {...sliderSetting}>
        {children}
      </Slider>
      {arrows && (
        <div className={`controls ${controlsStyle[arrowPosition]}`}>
          <div
            className={`flex justify-center items-center rounded-full cursor-pointer ${iconSize}  ${iconBg} ${prevIconClass}`}
            onClick={slider?.slickPrev}
          >
            {leftArrowIcon}
          </div>
          <div
            className={`flex justify-center items-center rounded-full cursor-pointer ${iconSize} ${iconBg} ${nextIconClass}`}
            onClick={slider?.slickNext}
          >
            {rightArrowIcon}
          </div>
        </div>
      )}
    </div>
  );
}
